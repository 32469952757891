import * as React from 'react';
import { Box, Container, Stack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import AgronomyResearch from '../../components/agronomy-research';
import '../../styles/crop-data.scss';

const SugarBeats = () => {
  return (
    <Layout>
      <SEO
        title="Crop Data - Sugar Beets | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        keywords="keywords"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Link className="back-button" to="/agronomy-research">
                  <ArrowBackIcon color="#044606" />
                  BACK
                </Link>
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Sugar Beets</h1>
                  <p>
                    Push the potential of your sugar beets by growing with
                    Crystal Green.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/crop-data/sugar-beets_banner.png"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        {/* Statistics */}
        <Box w="100%" p={{ lg: 4 }} className="crop-data-body-container">
          <Container
            className="crop-data-statistics"
            maxW={1400}
            marginTop={100}
            paddingRight={{ sm: 10, lg: 14 }}
          >
            <Box>
              <p className="crop-data-statistics__heading">Replicated Trials</p>
              <StaticImage
                placeholder="transparent"
                src="../../images/crop-data/sugar-beat_replicated-trials-1.png"
                alt="Corn Banded Trials"
                className="crop-data-statistics__image"
              />
              <StaticImage
                placeholder="transparent"
                src="../../images/crop-data/sugar-beat_replicated-trials-2.png"
                alt="Corn Banded Trials"
                className="crop-data-statistics__image"
              />
              <StaticImage
                placeholder="transparent"
                src="../../images/crop-data/sugar-beat_replicated-trials-3.png"
                alt="Corn Banded Trials"
                className="crop-data-statistics__image"
              />
              <StaticImage
                placeholder="transparent"
                src="../../images/crop-data/sugar-beat_replicated-trials-4.png"
                alt="Corn Banded Trials"
                className="crop-data-statistics__image"
              />
            </Box>
          </Container>
        </Box>
        {/* Agronomy Research Section */}
        <AgronomyResearch />
      </main>
    </Layout>
  );
};

export default SugarBeats;
